window._ = require('lodash');

try {
  window.Popper = require('@popperjs/core');
  window.$ = window.jQuery = require('jquery');
  
  require('owl.carousel');
  require('@fancyapps/fancybox');
  require('sweetalert');

  require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';



 
let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    $.ajaxSetup({headers: {'X-CSRF-TOKEN':token.content}});
} else {
    console.error('CSRF token not found');
}


/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });



// OWL Full With
$(document).ready(function () {
  $(".fw").owlCarousel({
    items: 1,
    dots:  true,
    nav:   true,
    loop:  true,

    animateOut: 'fadeOut',
    slideSpeed: 2000,
    
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,

  })
});

// OWL Full With

$(document).ready(function () {
  $('.m-slider-thump').owlCarousel({
    items: 1 ,
    dots:  true,
    nav:   true,
    loop:  true,
    
    animateOut: 'fadeOut',
    slideSpeed: 2000,

    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
  
  })
});

// Vitrin
$(document).ready(function () {
  $('.vt').owlCarousel({
    dots: false,
    nav: true,

    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 2500,

    responsiveClass: true,
    responsive: {

      0: { items: 1 },
      576: { items: 2 },
      768: { items: 2 },
      992: { items: 3 },
      1200: { items: 4 },
      1400: { items: 4 }
    }

  })
});

// Haberler Vitrin 
$(document).ready(function () {
  $('.vthaber').owlCarousel({
    dots: false,
    nav: true,

    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 2500,

    responsiveClass: true,
    responsive: {

      0: { items: 1 },
      576: { items: 2 },
      768: { items: 1 },
      992: { items: 1 },
      1200: { items: 2 },
      1400: { items: 2 }

    }

  })
});

// Grouplar Vitrin
$(document).ready(function () {
  $('.vtgroup').owlCarousel({
    dots: false,
    nav: true,

    autoplay: true,
    autoplayHoverPause: true,
    autoplayTimeout: 2500,

    responsiveClass: true,
    responsive: {

      0: { items: 2 },
      576: { items: 4 },
      768: { items: 5 },
      992: { items: 6 },
      1200: { items: 5 },
      1400: { items: 5 }

    }

  })
});
